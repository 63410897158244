<template>
  <v-list dense style="height:400px;overflow:auto">
    <v-list-item v-for="({ _id, pidm, user: commentUser, date, comment, priv }, index) in comments" :key="'comment-' + index">
      <v-list-item-content>
        <v-list-item-title>{{ priv ? 'Private comment' : 'Comment' }} by {{ commentUser }} on {{ stringFormatDate(date) }}</v-list-item-title>
        <v-list-item-subtitle v-if="!priv || pidm === user.pidm || accessRole === 'Admin'" style="white-space:normal">{{ comment }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="accessRole === 'Admin' && (!priv || pidm === user.pidm)">
        <v-btn icon @click="removeComment(_id)">
          <v-icon color="error">fal fa-times-circle</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>
<script>
import { stringFormatDate } from '../../../../helpers/formatters'
import { ref, computed, watch, onMounted, onBeforeUnmount } from '@vue/composition-api'

export default {
  props: {
    submissionId: {
      type: String,
      required: true
    },
    accessRole: {
      type: String,
      required: true
    }
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const comments = ref([])

    function onPatched (data) {
      if (data._id === props.submissionId) {
        comments.value = data.comments
      }
    }

    onMounted(() => {
      root.$feathers.service('forms/submission').on('patched', onPatched)
    })
    onBeforeUnmount(() => {
      root.$feathers.service('forms/submission').removeListener('patched', onPatched)
    })

    watch(() => props.submissionId, () => {
      root.$feathers.service('forms/submission').get(props.submissionId).then((data) => {
        if ('comments' in data && Array.isArray(data.comments)) comments.value = data.comments
        else comments.value = []
      })
    })

    function removeComment (_id) {
      root.$feathers.service('forms/submission').patch(props.submissionId, { $pull: { comments: { _id } } })
    }

    return {
      user,
      comments,
      removeComment,
      stringFormatDate
    }
  }
}
</script>
